@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --dark-navy: #020c1b;
  --navy: #0a192f;
  --light-navy: #112240;
  --lightest-navy: #233554;
  --navy-shadow: rgba(2, 12, 27, 0.7);
  --dark-slate: #495670;
  --slate: #8892b0;
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;
  --white: #e6f1ff;
  --green: #64ffda;
  --green-tint: rgba(100, 255, 218, 0.1);
  --pink: #f57dff;
  --blue: #57cbff;
}
::-webkit-scrollbar {
  display: none;
}
@font-face {
  font-family: "Calibre-Semibold";
  src: url("./fonts/Calibre/Calibre-Semibold.ttf") format("truetype");
}
@font-face {
  font-family: "Calibre-Regular";
  src: url("./fonts/Calibre/Calibre-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "SFMono-Regular";
  src: url("./fonts/SFMono/SFMono-Regular.ttf") format("truetype");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Calibre-Regular";
}
html {
  scroll-behavior: smooth;
  scroll-padding: 8rem;
}
body {
  background-color: #0a192f;
  color: #8892b0;
  line-height: 1.3;
  font-size: 20px;
}
.font-sf {
  font-family: "SFMono-Regular";
}
.nav-links a,
span {
  font-family: "SFMono-Regular";
}
.nav-links a span {
  color: #64ffda;
}
.nav-links a:hover {
  color: #64ffda;
  transition: 0.3s;
}
.highlighted-text {
  color: #64ffda;
}
.secondary-text {
  color: #8892b0;
}
.project-card {
  background-color: #112240;
}
.skills-text,
.project-text {
  color: #a8b2d1;
}
@media (max-width: 768px) {
  .project-text {
    color: #ccd6f6;
  }
}
.title {
  color: #ccd6f6;
}
.big-heading {
  font-size: clamp(40px, 8vw, 80px);
}

.medium-heading {
  font-size: clamp(40px, 8vw, 60px);
}

.resume-link:hover {
  transform: translateY(-3px);
  transition: 0.3s all ease;
  box-shadow: 4px 5px 0 #64ffda;
}

.hamburger:focus {
  outline: 2px solid #64ffda;
}
.icons:hover svg {
  transform: translateY(-4px);
  transition: 0.3s all ease-in-out;
}
.icons:hover svg {
  stroke: #ccd6f6;
}
.numbered-heading {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  width: 100%;
  font-size: clamp(26px, 5vw, 32px);
  white-space: nowrap;
}

.noteworthy-projects {
  position: relative;
  width: 100%;
  font-size: clamp(26px, 5vw, 32px);
  white-space: nowrap;
}

.grid-cols-responsive {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2.5rem;
}

.title-number {
  margin-right: 10px;
  color: #64ffda;
  font-size: clamp(16px, 3vw, 20px);
  font-weight: 400;
}
.numbered-heading::after {
  content: "";
  display: block;
  position: relative;
  top: -3px;
  width: 60%;
  height: 1px;
  margin-left: 20px;
  background-color: #233554;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: #ccd6f6;
  line-height: 1.1;
}
.skills li {
  position: relative;
  margin-bottom: 10px;
  padding-left: 20px;
  font-family: "SFMono-Regular";
}
.skills li::before {
  content: "▹";
  position: absolute;
  left: 0px;
  top: 3.5px;
  color: #64ffda;
  font-size: 20px;
  line-height: 12px;
}
.profile img {
  transition: 0.5s;
  filter: grayscale(100%) contrast(1);
}
.profile img:hover {
  transform: translate(-5px, -5px);
  filter: grayscale(0%) contrast(1);
}
.rect {
  transition: 0.5s all ease;
}
.profile-section:hover .rect {
  transform: translate(3px, 3px);
}

.skills-transition {
  transition: 0.5s all ease;
}
@media (min-width: 768px) {
  .section {
    /* flex: 1 1 50%;  */
    display: flex;
    flex-direction: row;
    gap: 20px;
    text-align: end;
  }
  .section:nth-child(even) {
    text-align: start;
    flex-direction: row-reverse;
  }
}

@media (max-width: 768px) {
  .details {
    min-height: 23rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .project-div {
    min-height: 20rem;
  }
}

/* .input-group input,.input-group textarea{
  width: 100%;
  padding: 5px;
  background-color: transparent;
  border-bottom: 0.5px solid #64ffda;
  }
  .input-group input,.input-group textarea{
  background-color: transparent;
  }
  .input-group input:focus,.input-group textarea:focus{
    outline: none;
    } */

.shadow-custom {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.427);
}

.otherProjectsCard:hover {
  transform: translateY(-8px);
  transition: 0.3s all ease-in-out;
}


.otherProjectsCard {
  height: auto;
  padding: 1rem;
}

@media (min-width: 640px) and (max-width: 845px) {
  .otherProjectsCard {
    height: 22rem;
    padding: 1rem;
  }
}
@media (min-width: 845px) and (max-width: 898px) {
  .otherProjectsCard {
    height: 22rem;
    padding: 1.3rem;
  }
}

@media (min-width: 898px) {
  .otherProjectsCard {
    height: 23rem;
    padding: 1.5rem;
  }
}

